@import 'index.scss';
@import "@progress/kendo-font-icons/scss/all.scss";


/* You can add global styles to this file, and also import other style files */
* {
  margin: 0px;
  font-family: "Roboto", sans-serif, "WebComponentsIcons";
}

button {
  border-radius: 1rem !important;
  padding: 1rem;
  max-height: 2rem;
  // max-width: 10rem;
}
kendo-dialog {
  z-index: 10000 !important;
}
kendo-window {
  z-index: 9999 !important;
}

kendo-expansionpanel {
  border-radius: 12px !important;
  
}


div.k-window.k-dialog {
  border-radius: 10px 10px 10px 10px !important;
}

kendo-dialog-titlebar {
  border-radius: 10px 10px 0px 0px !important;
}

div.fit {
  margin-bottom: 0px;
  width: 100%;
  kendo-card {
    $height: 56px;
    width: 100% !important;
    padding: 0px;
    // height: calc(100vh - 212px);
    // max-height: 300px;
    kendo-card-header {
      height: $height;
      width: 100%;
    }
    kendo-card-actions {
      padding: 0.5rem;
      height: 44px;
      button {
        margin-top: 0px;
        margin-bottom: 0px;
      }
    }

    kendo-grid {
      margin-bottom: 0px;
      height: calc(100vh - 212px);
    }
    kendo-card-body {
      padding: 0.5rem;
      margin-bottom: 0px;
      height: calc(100vh - 212px);
      overflow-y: scroll;
    }
    kendo-expansionpanel {
      width: 100%;
    }
  }
}

div.wrapp {
  width: 100%;
  kendo-card {
    $height: 56px;
    width: 100% !important;

    kendo-card-header {
      height: $height;
      width: 100%;
    }
    kendo-card-actions {
      padding: 0.5rem;
      height: 44px;
    }
    kendo-card-body {
      width: 100%;
      overflow-y: scroll;
      margin-bottom: 0px;
      height: calc(100vh - 212px);
      padding: 0.5rem;
    }
  }
}


.topnav {
  height: 60px;
  width: 100%;
  /* background-color: blue; */
}

.tree {
  width: 100%;
  /* background-color: red; */
  height: calc(100vh - 80px);
  /* border-right: 1px solid black; */
}
.breadcrumb {
  width: 100%;
  /* background-color: red; */
  height: 40px;
  /* border-right: 1px solid black; */
}

.splitterpane {
  /* background-color: red; */
  height: calc(100vh - 80px);
}



// Tree view

kendo-treeview {
  font-size: 1rem;
  /* color: red; */
}

.fixed {
  width: 100% !important;
}

.card-scroll {
  width: 100% !important;
  height: calc(100vh - 213px);
  overflow-x: auto;
  overflow-y: scroll;
}

// Breadcrumb
.item {
  display: flex;
  align-items: center;
  /* font-size: 14px; */
  /* border-radius: 1rem; */
  cursor: pointer;
  height: 100%;
  padding: 0.5rem;
}

.item__index {
  margin-right: 5px;
}

.border-last{
  border-radius: 0rem;
}

kendo-breadcrumb {
  padding-left: 0.5rem;
}

// table
tr {
  height: 36px;
}
button {
  margin-right: 0.5rem;
}


// IDENTITIES

button {
  margin-right: 0.5rem;
}


.hidden {
 color: rgb(129, 129, 129);
 font-size: 0.6rem;
}

// ASSET SELECTOR

.icontag {
  margin-right: 0.5rem;
  min-width: 1rem;
}

.selectview {
  display: flex;

  max-height: 70vh;
  min-height: 70vh;
  width: 80vw;

  .treeview {
    width: 30%;
    kendo-treeview {
      max-height: 70vh;
    }
  }

  .detailview {
    width: 70%;
    padding: 0.5rem;
    kendo-textbox {
      margin-bottom: 0.5rem;
    }
    form {
      margin-bottom: 0.5rem;
    }

    kendo-grid {
      min-height: 55vh;
      max-height: 55vh;
    }
  }
}

.list {

  kendo-card {
    kendo-card-body {
      kendo-grid {
        margin-bottom: 1rem;
      }
    }

  }
}
.action-btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  button {
    width: 300px;
  }
}
// kendo-grid {
//   height: 100%;
//   // max-height: calc(60vh - 100px);
//   margin-bottom: 1rem;

// }

// EXPORT BROWSER

kendo-formfield {
  margin-top: 1rem;
}

// TABLE

.multiselect {
  padding-left: 0.5rem;
}

// FORM

kendo-label {
  font-weight: 700;
  margin-bottom: 5px;
}


kendo-slider {
  width: 100%;
}

div .form-error {
  width: 100%;
  border-radius: 1rem;
}

.collumns {
  width: 100%;
  display: flex;
  justify-items: center;
  app-form {
    width: 100%;
    display: flex;
    div {
      width: 100%;

      form {
        width: 100%;
        display: flex;
        div {
          margin-right: 1rem;
        }
        div:last-child {
          margin-right: 0rem;
        }
      }
    }
  }
}

.col-items {
  display: flex;
  flex-wrap: wrap;

}

div.label {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border-radius: 1rem;
}

div.border-info {
  border: 0.1rem solid #0000000a;
}
div.border-warning {
  border: 0.1rem solid #ff5c1a;
}
div.border-danger {
  border: 0.1rem solid red;
}

div.bg-info {
  background-color: #0000000a;
}
div.bg-warning {
  background-color: #ff5c1a;
}
div.bg-danger {
  background-color: red;
}

div.font-h1 {
  font-size: 2.5em;
}

div.font-h2 {
  font-size: 1.875em;
}

div.font-p {
  font-size: 0.875em;
}

// kendo-expansionpanel.border-info {
//   border: 0.1rem solid $headings-text;
// }
// kendo-expansionpanel.border-warning {
//   border: 0.1rem solid $warning;
// }
// kendo-expansionpanel.border-danger {
//   border: 0.1rem solid $error;
// }

// kendo-expansionpanel.bg-info {
//   background-color: $info;
// }
// kendo-expansionpanel.bg-warning {
//   background-color: $warning;
// }
// kendo-expansionpanel.bg-danger {
//   background-color: $error;
// }

// LOADER



.topnav {
  height: 60px;
  width: 100%;
}

.tree {
  width: 100%;
  height: calc(100vh - 110px);
}
.breadcrumb {
  width: 100%;
  height: 40px;
}

.splitterpane {
  height: calc(100vh - 110px);
}

.loader {
	z-index: 1000;
	position: absolute;
  top: 0;
  left: 0px;
  width: 100%;
  /* background-color: rgb(0, 0, 0, 0.4); */
  height: 100vh;
}
.align-loader {
  position: absolute;
  top: 50%;
  left: 50%;
}



